import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/actelion/actelion.png"
import actelion1 from "../../../assets/images/portfolio_items/actelion/actelion1.png"
import actelion2 from "../../../assets/images/portfolio_items/actelion/actelion2.png"
import actelion3 from "../../../assets/images/portfolio_items/actelion/actelion3.png"
import actelion4 from "../../../assets/images/portfolio_items/actelion/actelion4.png"
import actelion5 from "../../../assets/images/portfolio_items/actelion/actelion5.png"
import actelion6 from "../../../assets/images/portfolio_items/actelion/actelion6.png"

export default () => 
<div>
    <SEO title={' ICON Worldwide | Actelion - AI Software Development Project'} 
    description="Discover more about Actelion Pharmaceuticals and the AI software development project we built for them: give a look at ICON's portfolio now!"
    canonical={'https://icon-worldwide.com/works/actelion'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <div className="title"><h1>Actelion<span></span></h1>
            <h2>Cutting-edge AI software development project </h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Actelion Pharmaceuticals is focussed on rare diseases. Our work for them includes complex software development, 
        community platforms and ai powered differential diagnosis websites.</p>
        <div className="portfolio-images">
            <img src={actelion1} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <img src={actelion3} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <img src={actelion5} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <img src={actelion2} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <img src={actelion4} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
            <img src={actelion6} alt=" Actelion Pharmaceuticals, ICON Worldwide portfolio, AI software development project" title="ICON Worldwide portfolio, Actelion Pharmaceuticals, AI software development project"/>
        </div>
        <WorkFooter previous="frankfurt-school" next="ceibs"/>
        </div>
    </div>
</div>